<template>
      <b-container v-if="!showTable"  class=" mt-5" >
        <b-skeleton-table :rows="5" :columns="4" :table-props="{ bordered: true, striped: true }" ></b-skeleton-table>
         </b-container>
</template>

<script>

export default {
    name: 'TableSkeleton',

    }
</script>


